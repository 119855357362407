import React from 'react';
import img from '../images/intro1.jpg';
import { Link } from 'react-router-dom';

const Intro = () => {
    return (
        <div id='Intro'>
            <div className="m-auto max-w-6xl p-4 md:p-12 h-full">

                <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                    <div className="lg:w-1/2 flex justify-center lg:justify-end lg:mx-4">
                        <img alt="card img" className="rounded-lg object-cover w-full lg:w-3/4" src={img} />
                    </div>
                    <div className="flex flex-col my-4 lg:my-0 lg:justify-center w-full lg:w-1/2 px-4 lg:px-8 text-center lg:text-left" data-aos="zoom-in" data-aos-delay="500">
                        <h3 className="text-2xl lg:text-3xl text-green-900 font-bold">
                            Transforming Waste into Opportunity
                        </h3>
                        <p className='my-3 text-lg lg:text-xl text-gray-700'>
                            At SOHAS, our vision is to lead the way in sustainable and innovative recycling solutions. We believe in transforming waste into valuable resources, making a positive impact on both the environment and our communities. By recycling soybean oil, we aim to reduce waste, conserve natural resources, and contribute to a cleaner, greener future. Our commitment is to create a circular economy where nothing is wasted, and everything is repurposed. Together, we can build a world where sustainability is at the core of every decision, and our planet thrives for generations to come.
                        </p>
                        <Link to="/contact" className="text-white bg-green-900 hover:bg-lime-700 inline-flex items-center justify-center w-full lg:w-auto px-6 py-3 my-4 text-lg shadow-lg rounded-full group transition-transform transform hover:-translate-y-1">
                            Contact us
                            <svg className="w-5 h-5 ml-2 transition-transform transform group-hover:translate-x-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro;
