import React from 'react';
import img from '../images/1.PNG'; 
import img2 from '../images/2.PNG';
import img3 from '../images/3.PNG';
import img4 from '../images/4.PNG';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-green-900 uppercase font-bold">services</h2>
                    
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-green-900'></div>
                    </div>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
                        
                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-800 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img} />
                                <h2 className="font-semibold my-6 text-2xl text-center">Collection and Sorting</h2>
                                <p className="text-md font-medium mt-4">
                                    Our process begins with the collection of waste soybean oil from various sources. We employ advanced sorting techniques to separate the oil from other waste materials. This initial step ensures that only the highest quality oil is processed, minimizing contaminants and maximizing efficiency.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-800 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-6 text-2xl text-center">Filtration and Purification</h2>
                                <p className="text-md font-medium mt-4">
                                    Once the waste oil is collected and sorted, it undergoes a rigorous filtration and purification process. Our cutting-edge filtration systems remove impurities and restore the oil to a usable state. This step is crucial for ensuring the quality of the end product and preparing it for further processing.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-800 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-6 text-2xl text-center">Conversion and Refinement</h2>
                                <p className="text-md font-medium mt-4">
                                    The purified soybean oil is then subjected to advanced conversion techniques. Through chemical and mechanical processes, we transform the waste oil into valuable by-products such as biodiesel, industrial lubricants, and other renewable resources. This stage is where the transformation of waste into useful materials truly takes place.
                                </p>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-800 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-6 text-2xl text-center">Quality Control and Distribution</h2>
                                <p className="text-md font-medium mt-4">
                                    Before reaching the market, all processed materials undergo stringent quality control tests to ensure they meet industry standards. Our dedicated quality assurance team monitors every stage of production. Once approved, the final products are distributed to various industries, contributing to a more sustainable future.
                                </p>
                            </div>
                        </div>                    
                    </div>
                </div>
            </section>

            <section>
            <div className="m-auto max-w-6xl p-4 md:p-12 h-full">
    <div className="flex flex-col lg:flex-row py-16 items-center lg:items-start justify-between text-center lg:text-left space-y-16 lg:space-y-0" data-aos="zoom-out">
        {/* First Section */}
        <div className="lg:w-1/2 flex flex-col lg:mx-4">
            <div className="text-green-900 mb-6 flex justify-center lg:justify-start">
            <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="currentColor" class="bi bi-buildings" viewBox="0 0 16 16">
  <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022M6 8.694 1 10.36V15h5zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5z"/>
  <path d="M2 11h1v1H2zm2 0h1v1H4zm-2 2h1v1H2zm2 0h1v1H4zm4-4h1v1H8zm2 0h1v1h-1zm-2 2h1v1H8zm2 0h1v1h-1zm2-2h1v1h-1zm0 2h1v1h-1zM8 7h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zM8 5h1v1H8zm2 0h1v1h-1zm2 0h1v1h-1zm0-2h1v1h-1z"/>
</svg>
            </div>
            <h3 className="text-4xl text-green-900 font-bold mb-4">We <span className="font-black">Build</span></h3>
            <p className="text-lg text-gray-600 leading-relaxed">
                With more than 10 years of expertise in recycling soybean oil waste, SOHAS has established itself as a leader in the industry. Our seasoned team of professionals is dedicated to continuous innovation, ensuring that our processes not only meet but exceed industry standards. We’ve spent years perfecting our techniques, allowing us to deliver exceptional results that benefit both our clients and the environment.
            </p>
        </div>

        {/* Second Section */}
        <div className="lg:w-1/2 flex flex-col lg:mx-4">
            <div className="text-green-900 mb-6 flex justify-center lg:justify-start">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="72" height="72" color="417505" fill="none">
    <path d="M20.7739 18C21.5232 18 22.1192 17.5285 22.6543 16.8691C23.7498 15.5194 21.9512 14.4408 21.2652 13.9126C20.5679 13.3756 19.7893 13.0714 18.9999 13M17.9999 11C19.3806 11 20.4999 9.88071 20.4999 8.5C20.4999 7.11929 19.3806 6 17.9999 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M3.2259 18C2.47659 18 1.88061 17.5285 1.34548 16.8691C0.250028 15.5194 2.04861 14.4408 2.73458 13.9126C3.43191 13.3756 4.21052 13.0714 4.99994 13M5.49994 11C4.11923 11 2.99994 9.88071 2.99994 8.5C2.99994 7.11929 4.11923 6 5.49994 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
    <path d="M8.08368 15.1112C7.0619 15.743 4.38286 17.0331 6.01458 18.6474C6.81166 19.436 7.6994 20 8.8155 20H15.1843C16.3004 20 17.1881 19.436 17.9852 18.6474C19.6169 17.0331 16.9379 15.743 15.9161 15.1112C13.52 13.6296 10.4797 13.6296 8.08368 15.1112Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.4999 7.5C15.4999 9.433 13.9329 11 11.9999 11C10.0669 11 8.49988 9.433 8.49988 7.5C8.49988 5.567 10.0669 4 11.9999 4C13.9329 4 15.4999 5.567 15.4999 7.5Z" stroke="currentColor" stroke-width="1.5" />
</svg>
            </div>
            <h3 className="text-4xl text-green-900 font-bold mb-4">We <span className="font-black">Collaborate</span></h3>
            <p className="text-lg text-gray-600 leading-relaxed">
                At SOHAS, we believe that collaboration is key to achieving a sustainable future. Whether you’re a business looking to implement eco-friendly practices or a community organization aiming to make a difference, we’re here to work with you. Together, we can create customized recycling solutions that align with your goals and contribute to a cleaner, healthier planet. Let’s partner for progress.
            </p>
        </div>
    </div>
</div>



            </section>
        </div>
    )
}

export default Services;
