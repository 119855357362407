import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { FaMapMarkerAlt } from 'react-icons/fa';

const Contact = () => {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ne4j07t",
        "template_uvkzx21",
        form.current,
        "JrJvnNe_U1nUgsBxN"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccessMessage("Message sent successfully");
          form.current.reset(); // Optionally reset the form
        },
        (error) => {
          console.log(error.text);
          setSuccessMessage(""); // Clear the message in case of error
        }
      );
  };

  return (
    <>
      <NavBar />
      <div className="min-h-screen bg-gray-50 flex items-center justify-center py-28 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-center text-3xl font-extrabold text-green-900">
              Contact Us
            </h2>
            <form ref={form} onSubmit={sendEmail} className="mt-8 space-y-6">
              <div className="rounded-md shadow-sm -space-y-px">
                <div>
                  <label htmlFor="user_name" className="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    name="user_name"
                    id="user_name"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Your Name"
                  />
                </div>
                <div>
                  <label htmlFor="user_email" className="sr-only">
                    Email
                  </label>
                  <input
                    type="email"
                    name="user_email"
                    id="user_email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Your Email"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="sr-only">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows="4"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Your Message"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-900 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Send
                </button>
              </div>
            </form>
            {successMessage && (
              <div className="mt-4 text-center text-green-700">
                {successMessage}
              </div>
            )}
          </div>
          <div className="p-4 md:p-6 lg:p-8 bg-white shadow-lg rounded-lg mx-4 md:mx-auto my-6 max-w-screen-sm">
<div className="text-center mb-4">
  <p className="text-lg text-gray-700 flex items-center justify-center">
    <FaMapMarkerAlt className="text-green-600 mr-2" />
    Address: Zone industrielle Jebel Ouest, Société SOHAS, Tunis
  </p>
  <hr className="border-t-2 border-gray-300 mt-4" />
</div>
            <div className="space-y-4">
              <div className="flex items-center space-x-3">
                <i className="fas fa-phone-alt text-green-600"></i>
                <div className="text-left">
                  <p className="font-semibold">Call Us:</p>
                  <p>
                    <a href="tel:+21698588579" className="text-green-600 underline">+216 98 588 579</a>
                    <span className="text-gray-600"> (Malek Hkim, Commercial)</span>
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <i className="fas fa-phone-alt text-green-600"></i>
                <div className="text-left">
                  <p className="font-semibold">Call Us:</p>
                  <p>
                    <a href="tel:+21653019466" className="text-green-600 underline">+216 53 019 466</a>
                    <span className="text-gray-600"> (Marwen Marweni, Qualité)</span>
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-3">
                <i className="fas fa-phone-alt text-green-600"></i>
                <div className="text-left">
                  <p className="font-semibold">Call Us:</p>
                  <p>
                    <a href="tel:+21698223396" className="text-green-600 underline">+216 98 223 396</a>
                    <span className="text-gray-600"> (Bilel Hkim, Logistiques)</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
