import React from 'react';
import { Link } from 'react-router-dom';
import NavBar from './Navbar/NavBar';
import heroImg from '../images/oil1.png';

const Hero = () => {
    return (
        <>
            <div className="hero" id='about'>
                <div>
                    <NavBar />
                </div>
                
                <div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">
                    <div id='hero' className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
                        <div className="lg:w-1/2 flex flex-col justify-center" data-aos="zoom-in" data-aos-delay="200">
                            <h1 className="mb-5 text-3xl sm:text-4xl md:text-5xl font-bold text-green-900 leading-tight">
                                Our Mission: 
                            </h1>
                            <div className="text-base sm:text-lg md:text-xl font-semibold tracking-tight mb-6 text-gray-700 leading-relaxed sm:leading-loose md:leading-loose max-w-prose">
    At SOHAS, we are dedicated to transforming the way we handle waste, particularly the waste generated from soybean oil. Our mission is to minimize environmental impact by recycling and repurposing waste materials into valuable resources. Through innovative processes and sustainable practices, we aim to contribute to a cleaner, greener planet while supporting industries with eco-friendly solutions. We believe that waste is not the end, but the beginning of something new and valuable.
</div>

                            <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                                <Link to="/contact" className="text-white bg-green-900 hover:bg-lime-700 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                    Learn more
                                    

                                    <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className="flex lg:justify-end w-full lg:w-1/2 mt-8 lg:mt-0" data-aos="fade-up" data-aos-delay="700">
                            <img alt="card img" className="rounded-t float-right duration-1000 w-full" src={heroImg} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;
