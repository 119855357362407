import React from 'react';
import logo from '../images/logo2.png';

const Footer = () => {
    return (
        <footer className="bg-green-900 text-white py-10">
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 px-6">
                
                <div>
                    <h3 className="text-lg font-semibold mb-4">About Us</h3>
                    <p className="text-gray-400 leading-relaxed">
                        SOHAS is dedicated to sustainable practices by recycling soybean oil to create a cleaner, greener future.
                    </p>
                    <img src={logo} alt="SOHAS Logo" className="mt-6 w-36 h-auto" />
                </div>

                <div>
                    <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
                    <ul className="space-y-3">
                        <li><a href="#about" className="text-gray-400 hover:text-white">About Us</a></li>
                        <li><a href="#services" className="text-gray-400 hover:text-white">Services</a></li>
                        <li><a href="#vision" className="text-gray-400 hover:text-white">Our Vision</a></li>
                        <li><a href="#contact" className="text-gray-400 hover:text-white">Contact Us</a></li>
                    </ul>
                </div>

                <div>
    <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
    <p className="text-gray-400 mb-2 flex items-center">
        <i className="fas fa-map-marker-alt mr-2" aria-hidden="true"></i>
        Zone Industrielle Jebel Ouest, Tunis
    </p>
    <p className="text-gray-400 mb-2 flex items-center">
        <i className="fas fa-envelope mr-2" aria-hidden="true"></i>
        Email: <a href="mailto:sohas.tunis@gmail.com" className="text-gray-400 hover:text-white">sohas.tunis@gmail.com</a>
    </p>
    <p className="text-gray-400 mb-2 flex items-center">
        <i className="fas fa-phone-alt mr-2" aria-hidden="true"></i>
        Phone: <a href="tel:+21698588579" className="text-gray-400 hover:text-white">+216 98 588 579</a>
    </p>
    {/* <p className="text-gray-400 mb-2 flex items-center">
        <i className="fas fa-code mr-2" aria-hidden="true"></i>
        Our Developer: 
        <a 
            href="https://www.linkedin.com/in/dhia-mbarki-331270209/" 
            className="text-blue-400 underline hover:text-blue-600 ml-1"
        >
            Dhia Mbarki
        </a>
    </p> */}
</div>



            </div>

            <div className="container mx-auto text-center mt-10">
                <div className="flex justify-center space-x-6">
                    <a href="#" className="text-gray-400 hover:text-white text-xl"><i className="fab fa-facebook-f"></i></a>
                    <a href="#" className="text-gray-400 hover:text-white text-xl"><i className="fab fa-twitter"></i></a>
                    <a href="#" className="text-gray-400 hover:text-white text-xl"><i className="fab fa-linkedin-in"></i></a>
                    <a href="#" className="text-gray-400 hover:text-white text-xl"><i className="fab fa-instagram"></i></a>
                </div>
            </div>

            <div className="container mx-auto text-center mt-10 border-t border-gray-800 pt-6">
                <p className="text-gray-500 text-sm">
                    &copy; 2024 SOHAS. All rights reserved.
                </p>
            </div>
        </footer>
    )
}

export default Footer;
