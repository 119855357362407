import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
            <HashLink className="px-4 font-extrabold text-gray-500 hover:text-lime-600" smooth to="/#about">
    ABOUT
</HashLink>
<HashLink className="px-4 font-extrabold text-gray-500 hover:text-lime-600" smooth to="/#services">
    SERVICES
</HashLink>
<HashLink className="px-4 font-extrabold text-gray-500 hover:text-lime-600" to="/#Intro">
  OUR VESION
</HashLink>
<HashLink className="px-4 font-extrabold text-gray-500 hover:text-lime-600" to="/contact#contact">
    CONTACT US
</HashLink>
{/* <HashLink
 className="text-white bg-green-900 hover:bg-lime-700 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl" smooth to="/get-demo#demo">
    CONNECT
</HashLink> */}

        </>
    )
}

export default NavLinks;
